import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SectionHeroPage from "../../components/sections/heroPage"
import HeadingSpkrsMobile from "../../images/svg/inline-svg/heading_spkrs_mobile.svg"
import HeadingSpkrs from "../../images/svg/inline-svg/heading_spkrs.svg"
import HeadingAgenda from "../../images/svg/inline-svg/heading_agenda.svg"
import HeadingSpeak from "../../images/svg/inline-svg/heading_speak.svg"
import HeadingAgendaMobile from "../../images/svg/inline-svg/heading_agenda_mobile.svg"
import HeadingSpeakMobile from "../../images/svg/inline-svg/heading_speak_mobile.svg"
import SectionMediaHalf from "../../components/sections/mediaHalf"
import SectionPastAgendas from "../../components/sections/sectionPastAgendas"
import SectionCurrentSponsors from "../../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrs from "../../images/svg/inline-svg/heading_past-spnsrs.svg"
import HeadingPastSpnsrsMobile from "../../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"
import SectionFeaturedSpeakers from "../../components/sections/speakers"
import SectionWidgetAgenda from "../../components/sections/widgetAgenda"

const AgendaPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              bizzaboEventId
              bizzaboUniqueName
              internalWebsiteBaseUrl
              year
              speakerApplicationLink
            }
          }
          headerImage: file(
            relativePath: { eq: "headers/hero-speakatascend.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 712) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerImageMobile: file(
            relativePath: { eq: "headers/hero-speakatascend-mobile.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 624) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          speakingOverview: file(
            relativePath: { eq: "WhatWereLookingFor.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          speakerPanel: file(
            relativePath: { eq: "collages/image-mosaic/2024/PastAscend-04.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktop: file(
            relativePath: {
              eq: "background/202002_Ascend_Website_Images_Hero_Agenda.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerImage = data.headerImage.childImageSharp.fluid
        const headerImageMobile = data.headerImageMobile.childImageSharp.fluid
        const speakingOverview = data.speakingOverview.childImageSharp.fluid
        const speakerPanel = data.speakerPanel.childImageSharp.fluid
        const year = data.site.siteMetadata.year
        const speakerApplicationLink =
          data.site.siteMetadata.speakerApplicationLink
        const eventId = data.site.siteMetadata.bizzaboEventId
        const bizzaboUniqueName = data.site.siteMetadata.bizzaboUniqueName
        const internalWebsiteBaseUrl =
          data.site.siteMetadata.internalWebsiteBaseUrl
        return (
          <Layout>
            <Seo
              title={`Agenda and Speakers | Ascend ${year}`}
              location={`https://ascendevent.com/agenda-and-speakers/`}
              description={`Learn more about who is speaking and what they're talking about for this year's Ascend ${year} physical therapy conference.`}
            />
            <SectionHeroPage
              heading=""
              subheading="Check Out Our</br> All-Star Lineup"
              image={headerImage}
              mediaMediumOffset={1}
              sectionHeading={<HeadingAgenda />}
              ctaText="Attend Ascend"
              ctaLink={`/tickets#register-for-ascend`}
              mobileSectionHeading={<HeadingAgendaMobile />}
              mobileImage={headerImageMobile}
            />
            <SectionMediaHalf
              image={speakingOverview}
              videoUrl="https://share.vidyard.com/watch/NyfzakNu1u53acLpoCF3n8"
              showVideo={true}
              sectionHeading=""
              textSmallOrder={2}
              textMediumOrder={2}
              textLargeOrder={2}
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={1}
              textWidthMedium={5}
              imageWidthMedium={6}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={0}
              mediaLargeOrder={1}
              mediaMediumOrder={1}
              mediaSmallOrder={1}
              mediumOrder={1}
              headingTag="h3"
              sectionBody="<h3>Ascend 2024 Overview</h3><p>
                    After a decade as the ultimate business and networking summit for rehab therapy professionals, we’re looking forward to our best year yet!</p><p>To maximize your experience, arrive Thursday afternoon between 12:00 PM and 4:00 PM to grab your badge so you can hit the ground running Friday morning. The rest of the weekend will be jam-packed with industry-leading speakers, curated networking events, and peer-to-peer workshops to discuss the topics you’re most interested in. And be sure to stick around for our last full day on Saturday to catch our send-off celebration, as we close our time together and get excited about the year ahead for rehab therapy.</p><p><strong>Therapists can earn up-to 9.5 CEU hours attending Ascend 2024 sessions.
                    <a href='https://21853389.fs1.hubspotusercontent-na1.net/hubfs/21853389/2024/ascend/Ascend2024_CE-Information.pdf' target='_blank' aria-label='Download Continuing Education information'>View details here</a>.
</strong></p>"
              ctaText="Apply Now"
            />
            <SectionWidgetAgenda
              eventId={eventId}
              internalWebsiteBaseUrl={internalWebsiteBaseUrl}
            />
            <SectionMediaHalf
              image={speakerPanel}
              videoUrl="https://share.vidyard.com/watch/w6fmjD5gYx282EzcdC3VvN"
              showVideo={false}
              heading={<HeadingSpkrs />}
              mobileSectionHeading={<HeadingSpkrsMobile />}
              textSmallOrder={1}
              textMediumOrder={1}
              textLargeOrder={1}
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={0}
              textWidthMedium={4}
              imageWidthMedium={6}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={1}
              mediaLargeOrder={2}
              mediaMediumOrder={2}
              mediaSmallOrder={2}
              mediumOrder={2}
              headingTag="h3"
              sectionBody="<h3>Rehab Therapy's Best And Brightest</h3><p>
                    Ascend is all about collaboration—and execution. So, we’re stoked to host a group of superstar speakers made up of industry leaders who will deliver wisdom and actionable insights you can use to improve your role, practice, and profession.</p><p>In addition to soaking up all the learning goodness, you’ll also have the chance to share your own expertise during meaningful discussions about technological, regulatory, clinical, and financial trends that will shape the future of outpatient rehab therapy.</p>"
              ctaLink=""
              ctaText=""
            />
            <SectionFeaturedSpeakers
              eventId={eventId}
              internalWebsiteBaseUrl={internalWebsiteBaseUrl}
              bizzaboUniqueName={bizzaboUniqueName}
            />
            <SectionPastAgendas />
            <SectionCurrentSponsors
              sectionHeading={<HeadingPastSpnsrs />}
              sectionHeadingMobile={<HeadingPastSpnsrsMobile />}
            />
          </Layout>
        )
      }}
    />
  )
}

export default AgendaPage
